<template>
  <div class="huankong">
    <div class="top">
      <div class="block block1">
        <div class="block-title">风机状态</div>

        <div class="block-content">
          <v-chart ref="chart" class="chart" autoresize :option="createOption1()" />
        </div>
      </div>
      <div class="block block2">
        <div class="block-title">风量数据</div>

        <div class="block-content">
          <v-chart ref="chart2" class="chart" autoresize :option="createOption2()" />
        </div>
      </div>
      <div class="block block3">
        <div class="block-title">单元环控设备状态</div>

        <div class="block-content">
          <ul>
            <li>
              <div class="li-wrapper">
                <p class="value">{{ '89' }}</p>
                <p class="label">环控设备数量</p>
              </div>
            </li>
            <li>
              <div class="li-wrapper">
                <p class="value">{{ '--' }}</p>
                <p class="label">环控设备数量</p>
              </div>
            </li>
            <li>
              <div class="li-wrapper">
                <p class="value">{{ '--' }}</p>
                <p class="label">环控设备数量</p>
              </div>
            </li>
            <li>
              <div class="li-wrapper">
                <p class="value">{{ '--' }}</p>
                <p class="label">环控设备数量</p>
              </div>
            </li>
            <li>
              <div class="li-wrapper">
                <p class="value">{{ '--' }}</p>
                <p class="label">环控设备数量</p>
              </div>
            </li>
            <li>
              <div class="li-wrapper">
                <p class="value">{{ '--' }}</p>
                <p class="label">环控设备数量</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bot">
      <div class="title">
        单元数据展示
      </div>
      <div class="body">

        <div class="status-box">
          <ul>
            <li><span class="icon rect"></span>在用</li>
            <li><span class="icon rect gray"></span>空舍</li>
            <li><span class="icon circle green"></span>在线</li>
            <li><span class="icon circle red"></span>离线</li>

          </ul>
        </div>

        <div class="lou">
          <div class="floor" v-for="(f, ind) in [1, 2, 3, 4, 5, 6, 7]" :key="ind">
            <div class="name">七楼</div>
            <div class="list">
              <span class="unit" v-for="(i, index) in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" :key="index">
                <span class="id">{{ i }}</span>
                <span class="wen">{{ i }}°C</span>
                <span class="sta green"></span>
              </span>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>


<script>
import { use, LinearGradientObject } from "echarts/core";
import { CanvasRenderer, LinearGradient } from "echarts/renderers";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { LineChart, GaugeChart } from "echarts/charts";
import * as echarts from "echarts/core"
use([
  GaugeChart,
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
]);

const color = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
  { offset: 0, color: '#0ecd27' },
  { offset: 1, color: '#9e1819' }
]);
// colorSet倒过来
const colorSet = [
  [0, 'transparent'],
  [1, color]
];

export default {
  name: "Page",
  components: { VChart },
  data() {
    return {
      form: {}
    }
  },
  methods: {

    createOption1() {
      const color = ["#78c03e", "#4d81e8", "#4a5178"];
      const _this = this;
      const percent = this.feedingData?.targetAmount
        ? (
          (this.feedingData?.amount || 0) /
          (this.feedingData?.targetAmount || 0)
        ).toFixed(2) *
        100 +
        "%"
        : "0%";
      return {
        color,
        textStyle: {
          fontSize: 16,
          rich: {
            a: {
              color: "#CEDFF5",
            },
            b: {
              color: "#54F244",
            },
          },
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          icon: "circle",
          bottom: 10,
          formatter: function (name) {
            let value = null;
            if (name === "采食量") {
              value = _this.feedingData?.amount || 0;
            } else if (name === "分配量") {
              value = _this.feedingData?.targetAmount || 0;
            }
            return `{a|${name}} {b|${value}}`;
          },
          textStyle: {
            color: "#B4C0D3",
          },
          itemWidth: 10,
          itemHeight: 10,
        },

        series: [
          {
            // name: "Access From",
            top: -30,
            type: "pie",
            radius: ["44%", "65%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              rich: {
                totalText: {
                  fontSize: 38,
                  color: "#fff",
                },
                active: {
                  fontSize: 18,
                  color: "#fff",
                  lineHeight: 40,
                  padding: [5, 6, 5, 6],
                  width: 70,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  boxShadow: "0px 1px 0px 0px rgba(0,0,0,0.39)",
                  opacity: 0.95,
                  borderRadius: 5,
                },
              },

              formatter:
                "{totalText|" + percent + "}" + "\n\r" + "{active|总量}",
              color: "#fff",
              position: "center",
            },

            labelLine: {
              show: false,
            },
            data: [
              { value: this.feedingData?.amount || 0, name: "开启" },
              { value: this.feedingData?.targetAmount || 0, name: "禁用" },
              { value: this.feedingData?.amount || 0, name: "异常" },
            ],
          },
        ],
      };
    },
    createOption2() {
      return {
        // tooltip: {
        //   formatter: '{a} <br/>{b} : {c}%'
        // },

        series: [
          {
            // name: 'Pressure',
            type: 'gauge',
            center: ['50%', '70%'],
            radius: '100%',
            min: 0,
            max: 100,
            startAngle: 180,
            endAngle: 0,
            itemStyle: {
              color: '#fff'
            },
            axisLine: {
              lineStyle: {
                width: 20,
                color: colorSet // 渐变色作为轴的背景色
              }
            },
            axisTick: {
              distance: -35,
              // splitNumber: 5,
              lineStyle: {
                width: 1,
                color: '#999'
              }
            },
            splitLine: {
              distance: -46,
              // length: 10,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            axisLabel: {
              distance: -10,
              color: '#fff',
              fontSize: 14
            },
            progress: {
              // show: true,
              width: 20
            },
            detail: {
              color: '#fff',
              // valueAnimation: true,
              offsetCenter: [0, '25%'],
              formatter: '{value}m³/h'
            },
            data: [
              {
                value: 50,
                // name: 'SCORE'
              }
            ]
          }
        ]
      }
    },
    async search() { }
  }
}
</script>
<style scoped lang="less" src="./style.less"></style>